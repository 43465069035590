<template>
  <main class="tour-passport w-100">
    <section class="tour-passport__container">
      <h1 class="title tour-passport__title">{{ $t("TOUR_ORDER.PASSPORTS_PLEASE") }}</h1>
      <p class="text tour-passport__text">{{ $t("TOUR_ORDER.TOUR_PASSPORT_TXT") }}</p>

      <p class="text tour-passport__text">{{ $t('TOUR_ORDER.PASSPORT_UPLOAD_STATE', { num1: uploaded_passportNumber, num2: total_passportNumber }) }}</p>

      <div class="tour-passport__added" v-if="passportData_receipts.length != 0">
        <div 
          v-for="(passport_item, index) in passportData_receipts"
          :key="index" 
          class="tour-passport__added-item"
        >
          <div class="tour-passport__added-passport before-none">
            <img :src="passport_item.MRZdata.face_image" class="tour-passport-image" />
            <button class="button tour-passport__added-pencil" @click="changePassImg(passport_item.MRZdata.MRZ)">
              <svg
                class="tour-passport__svg-small"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="#000"
              >
                <path
                  d="M19.268,2.2,17.8.733a2.5,2.5,0,0,0-3.536,0L.5,14.5l-.5,4.46a.938.938,0,0,0,1.035,1.035l4.46-.5L19.268,5.733a2.5,2.5,0,0,0,0-3.536ZM15.55,7.684,6.8,16.436V15.079H4.922V13.2H3.565l8.752-8.752L15.55,7.684ZM4.928,18.305l-2.3.256L1.44,17.378l.256-2.3.776-.776H3.829v1.875H5.7V17.53ZM18.384,4.849,16.434,6.8,13.2,3.568l1.951-1.951a1.249,1.249,0,0,1,1.768,0l1.464,1.464A1.249,1.249,0,0,1,18.384,4.849Z"
                  transform="translate(-0.001 -0.001)"
                />
              </svg>
            </button>
            <button class="button tour-passport__added-trash" @click="removePassImg(passport_item.MRZdata.MRZ)">
              <svg
                class="tour-passport__svg-small"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="#000"
              >
                <path
                  d="M17.188,2.5H13.125L11.812.75a1.875,1.875,0,0,0-1.5-.75H7.188a1.875,1.875,0,0,0-1.5.75L4.375,2.5H.313A.313.313,0,0,0,0,2.813v.625a.312.312,0,0,0,.313.312h.738l1.3,14.543A1.875,1.875,0,0,0,4.215,20h9.07a1.875,1.875,0,0,0,1.867-1.707l1.3-14.543h.738a.313.313,0,0,0,.313-.312V2.813A.313.313,0,0,0,17.188,2.5Zm-10.5-1a.629.629,0,0,1,.5-.25h3.125a.629.629,0,0,1,.5.25l.75,1H5.937Zm7.219,16.68a.621.621,0,0,1-.621.57H4.215a.621.621,0,0,1-.621-.57L2.3,3.75H15.2Z"
                />
              </svg>
            </button>
          </div>
          <p class="text tour-passport__added-text">{{ $t("TOUR_ORDER.PASSENGER") }} {{index + 1}}</p>
        </div>
      </div>
      <div class="tour-passport__added" v-if="passportManualData.length != 0">
        <div 
          v-for="(passport_item, index) in passportManualData"
          :key="index" 
          class="tour-passport__added-item"
        >
          <div class="tour-passport__added-passport">
            <button class="button tour-passport__added-pencil"  @click="editPassManualData(passport_item.passport_number)">
              <svg
                class="tour-passport__svg-small"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="#000"
              >
                <path
                  d="M19.268,2.2,17.8.733a2.5,2.5,0,0,0-3.536,0L.5,14.5l-.5,4.46a.938.938,0,0,0,1.035,1.035l4.46-.5L19.268,5.733a2.5,2.5,0,0,0,0-3.536ZM15.55,7.684,6.8,16.436V15.079H4.922V13.2H3.565l8.752-8.752L15.55,7.684ZM4.928,18.305l-2.3.256L1.44,17.378l.256-2.3.776-.776H3.829v1.875H5.7V17.53ZM18.384,4.849,16.434,6.8,13.2,3.568l1.951-1.951a1.249,1.249,0,0,1,1.768,0l1.464,1.464A1.249,1.249,0,0,1,18.384,4.849Z"
                  transform="translate(-0.001 -0.001)"
                />
              </svg>
            </button>
            <button class="button tour-passport__added-trash" @click="removePassManualData(passport_item.passport_number)">
              <svg
                class="tour-passport__svg-small"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="#000"
              >
                <path
                  d="M17.188,2.5H13.125L11.812.75a1.875,1.875,0,0,0-1.5-.75H7.188a1.875,1.875,0,0,0-1.5.75L4.375,2.5H.313A.313.313,0,0,0,0,2.813v.625a.312.312,0,0,0,.313.312h.738l1.3,14.543A1.875,1.875,0,0,0,4.215,20h9.07a1.875,1.875,0,0,0,1.867-1.707l1.3-14.543h.738a.313.313,0,0,0,.313-.312V2.813A.313.313,0,0,0,17.188,2.5Zm-10.5-1a.629.629,0,0,1,.5-.25h3.125a.629.629,0,0,1,.5.25l.75,1H5.937Zm7.219,16.68a.621.621,0,0,1-.621.57H4.215a.621.621,0,0,1-.621-.57L2.3,3.75H15.2Z"
                />
              </svg>
            </button>
          </div>
          <p class="text tour-passport__added-text">{{ $t("TOUR_ORDER.PASSENGER") }} {{index + 1}}</p>
        </div>
      </div>
      <div v-if="!isContinue">
        <div class="tour-passport__photo">
          <svg
            class="tour-passport__svg"
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="20"
            viewBox="0 0 23 20"
          >
            <path
              d="M11.429,48.786a5.357,5.357,0,1,1,5.357-5.357A5.361,5.361,0,0,1,11.429,48.786Zm0-9.286a3.929,3.929,0,1,0,3.929,3.929A3.934,3.934,0,0,0,11.429,39.5ZM10,43.429A1.433,1.433,0,0,1,11.429,42a.714.714,0,0,0,0-1.429,2.86,2.86,0,0,0-2.857,2.857.714.714,0,1,0,1.429,0Zm4.478-10a.362.362,0,0,1,.335.232l.987,2.625h4.915a.716.716,0,0,1,.714.714V49.857a.716.716,0,0,1-.714.714H2.143a.716.716,0,0,1-.714-.714V37a.716.716,0,0,1,.714-.714h4.92l.9-2.393a.716.716,0,0,1,.67-.464h5.848m0-1.429H8.629a2.14,2.14,0,0,0-2,1.388l-.554,1.469H2.143A2.143,2.143,0,0,0,0,37V49.857A2.143,2.143,0,0,0,2.143,52H20.714a2.143,2.143,0,0,0,2.143-2.143V37a2.143,2.143,0,0,0-2.143-2.143H16.786l-.638-1.7A1.775,1.775,0,0,0,14.478,32Z"
              transform="translate(0 -32)"
            />
          </svg>
          <input class="tour-passport__file" type="file" @change="handleImage" accept="image/*" ref="file" multiple="multiple"/>
        </div>
        <p class="text tour-passport__text-passport">{{ $t("TOUR_ORDER.PASSPORT_PHOTO") }}</p>

        <p class="text tour-passport__text-cant">{{ $t("TOUR_ORDER.TOUR_PASSPORT_TXTCANT") }}</p>

        <router-link to="/passport-detail/create" tag="a" class="link tour-passport__link">
          {{ $t("TOUR_ORDER.ENTER_MANUALLY") }}
        </router-link>
      </div>
      <div v-else>
        <router-link  :to="{ name: 'tour-order5', params: { transaction_id: transactionId, phone_number: phoneNumber } }" tag="a" class="link tour-payment__link">
          {{ $t("COMMON.CONTINUE") }}
        </router-link>
      </div>
      
      <!-- <a class="link tour-passport__link" href="tour-order-4.html">{{ $t("TOUR_ORDER.ENTER_MANUALLY") }}</a> -->
    </section>

    <!-- <section class="tour-passport__modal">
      <div class="tour-passport__modal-container">
        <button class="button tour-passport__modal-close">
          <img src="assets/img/close.svg" alt />
        </button>

        <h2 class="title tour-passport__modal-title">{{ $t("TOUR_ORDER.TOUR_PASSPORT_MTITLE") }}</h2>
        <p class="text tour-passport__modal-text">{{ $t("TOUR_ORDER.TOUR_PASSPORT_MTXT") }}</p>

        <div class="tour-passport__modal-photo">
          <svg
            class="tour-passport__svg"
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="20"
            viewBox="0 0 23 20"
          >
            <path
              d="M11.429,48.786a5.357,5.357,0,1,1,5.357-5.357A5.361,5.361,0,0,1,11.429,48.786Zm0-9.286a3.929,3.929,0,1,0,3.929,3.929A3.934,3.934,0,0,0,11.429,39.5ZM10,43.429A1.433,1.433,0,0,1,11.429,42a.714.714,0,0,0,0-1.429,2.86,2.86,0,0,0-2.857,2.857.714.714,0,1,0,1.429,0Zm4.478-10a.362.362,0,0,1,.335.232l.987,2.625h4.915a.716.716,0,0,1,.714.714V49.857a.716.716,0,0,1-.714.714H2.143a.716.716,0,0,1-.714-.714V37a.716.716,0,0,1,.714-.714h4.92l.9-2.393a.716.716,0,0,1,.67-.464h5.848m0-1.429H8.629a2.14,2.14,0,0,0-2,1.388l-.554,1.469H2.143A2.143,2.143,0,0,0,0,37V49.857A2.143,2.143,0,0,0,2.143,52H20.714a2.143,2.143,0,0,0,2.143-2.143V37a2.143,2.143,0,0,0-2.143-2.143H16.786l-.638-1.7A1.775,1.775,0,0,0,14.478,32Z"
              transform="translate(0 -32)"
            />
          </svg>
          <input class="tour-passport__modal-file" type="file" />
        </div>

        <p class="text tour-passport__modal-label">{{ $t("TOUR_ORDER.PASSPORT_PHOTO") }}</p>

        <p class="text tour-passport__modal-cant">{{ $t("TOUR_ORDER.TOUR_PASSPORT_TXTCANT") }}</p>

        <a class="link tour-passport__modal-link" href="tour-order-4.html">{{ $t("TOUR_ORDER.ENTER_MANUALLY") }}</a>
      </div>
    </section> -->
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: "OrderPassport",
  components: {
    Loading 
  },
  computed: {
    ...mapState({
      loading: state => state.tour_order.loading,
      passportData_receipts: state => state.tour_order.passportData_receipts,
      passportManualData: state => state.tour_order.passportManualData,
      transactionData: state => state.tour_order.transactionData,
    }),
    transactionId() {
      return localStorage.getItem("transactionId");
    },
    phoneNumber() {
      return localStorage.getItem("phone_number");
    },
    uploaded_passportNumber() {
      return this.passportData_receipts.length*1 + this.passportManualData.length*1;
    }
  },
  async created() {
    const transaction_id = this.$route.params.transaction_id;
    const phoneNumber = this.$route.params.phone_number;
    await this.getTransactionData({ id: transaction_id, phoneNumber: phoneNumber });
    if(this.transactionData) {
      /*  set the total passportNumber */
      this.transactionData.dealOrder.DealOrderItems.forEach(ele => {
        this.total_passportNumber += ele.paxComplect.length;
      });
    }
    this.checkPassportNum();
  },
  data() {
    return {
      total_passportNumber: 0,
      isContinue: false
    }
  },
  methods: {
    ...mapActions("tour_order", {
      uploadPassImage: "uploadPassImage",
      removePassData: "removePassData",
      changePassImageData: "changePassImageData",
      removePassMData: "removePassMData",
      getTransactionData: "getTransactionData"
    }),
    async handleImage(e) {
      let formData = new FormData();
      for( var i = 0; i < this.$refs.file.files.length; i++ ){
        let file = this.$refs.file.files[i];
        formData.append('scan_images[' + i + ']', file);
      }
      await this.uploadPassImage({scan_images: formData});
    },

    removePassImg(mrz) {
      this.removePassData({'mrz': mrz});
      this.checkPassportNum();
    },
    changePassImg(mrz) {
      this.changePassImageData({ 'mrz': mrz });
    },
    removePassManualData(id) {
      this.removePassMData({'id': id});
      this.checkPassportNum();
    },
    editPassManualData(id) {
      this.$router.push("/passport-detail/" + id);
    },
    checkPassportNum() {
      if(this.total_passportNumber == this.uploaded_passportNumber) {
        this.isContinue = true;
      } else {
        this.isContinue = false;
      }
    }
  },
  mounted() {
    localStorage.setItem("transactionId", this.$route.params.transaction_id);
    localStorage.setItem("phone_number", this.$route.params.phone_number);
  }
};
</script>
<style>
  .tour-passport__added {
    flex-wrap: wrap;
  }

  .before-none::before {
    display: none;
  }

  .tour-passport-image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>